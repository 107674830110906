import React from 'react';
import Image from '@jetshop/ui/Image/Image';
import { cx } from 'linaria';
import { LinkEvaluator } from 'r3-lib/dist/r3lib';
import { StyledPrice } from '../../../CategoryPage/ProductCard';
import { cartProductCardStyle } from './CartProductCard.style';
import { theme } from '../../../Theme';
import getCartItemVariant from '@jetshop/core/helpers/getCartItemVariant';
import useDecrementQuantity from '@jetshop/core/components/Mutation/useDecrementQuantity';
import useIncrementQuantity from '@jetshop/core/components/Mutation/useIncrementQuantity';
import useSetQuantity from '@jetshop/core/components/Mutation/useSetQuantity';
import useRemoveFromCart from '@jetshop/core/components/Mutation/useRemoveFromCart';
import { QtyAdjuster } from '../QtyAdjuster';
import removeFromCartMutation from '../../../Cart/queries/removeFromCart.gql';
import incrementQuantityMutation from '../../../Cart/queries/incrementQuantity.gql';
import decrementQuantityMutation from '../../../Cart/queries/decrementQuantity.gql';
import setQuantityMutation from '../../../Cart/queries/setItemQuantity.gql';
import cartQuery from '../../../Cart/queries/cartQuery.gql';
import { SVG } from '../../../../assets/SVG';

export const CartProductCard = ({ item, linkClickCallback }) => {
  const {
    // isVariant,
    variantImage,
    // variantValues,
    hasVariantImage
  } = getCartItemVariant(item);

  const { incrementQuantity } = useIncrementQuantity({
    incrementQuantityMutation,
    cartQuery
  });

  const { decrementQuantity } = useDecrementQuantity({
    decrementQuantityMutation,
    cartQuery
  });

  const { removeFromCart } = useRemoveFromCart({
    removeFromCartMutation,
    cartQuery
  });

  const { setQuantity } = useSetQuantity({
    setQuantityMutation,
    cartQuery
  });

  const handleInCrementQuantity = () => {
    incrementQuantity({ itemId: item.id });
  };
  const handleDeCrementQuantity = () => {
    decrementQuantity({ itemId: item.id });
  };

  const handleSetQantity = newQty => {
    setQuantity({ itemId: item.id, quantity: newQty });
  };

  // const variantNamesValues = variantValues?.map(
  //   (value, index) => `${item?.variantOptionNames?.[index]}: ${value}`
  // );

  // const isFreeItem =
  //   item.discounts?.filter(d => {
  //     return d.type === 'FREE_PRODUCT';
  //   }).length > 0;
  const noImage =
    'https://willabb2c-eclipse.jetshop.se/pub_docs/files/missingImage.png';
  const product = item?.product;
  const productImage = hasVariantImage
    ? variantImage?.url
    : product?.images?.[0]?.url ?? noImage;

  const brand = product?.customFields?.filter(
    customField => customField.key === 'Varumarke'
  );

  return (
    <div className={cx('cart-product-card', cartProductCardStyle)}>
      <div className="cart-item-image">
        <Image
          sizes={theme.layout.imageSizes.grid}
          aspect={theme.layout.productImageAspectRatio}
          alt={product?.images?.[0]?.alt}
          src={productImage}
          fillAvailableSpace={true}
          modifiedDate={product?.images?.[0]?.modifiedDate}
          critical={true}
          crop={true}
        />
      </div>

      <div className="product-information">
        <LinkEvaluator
          linkClickCallback={linkClickCallback}
          link={product?.primaryRoute?.path}
        >
          <h4>{brand?.[0]?.stringValue || product.subName}</h4>
          <p>{product?.name}</p>
        </LinkEvaluator>
        <QtyAdjuster
          qty={item?.quantity}
          incrementCallback={handleInCrementQuantity}
          decrementCallback={handleDeCrementQuantity}
          setQuantityCallback={handleSetQantity}
        />
      </div>

      <div className="cart-product-price">
        <StyledPrice
          data-testid="item-price"
          price={item?.total}
          previousPrice={item?.previousTotal}
        />
      </div>
      <div className="remove">
        <button onClick={() => removeFromCart({ itemId: item.id, product })}>
          <div className="svg-wrapper">
            <SVG name={'X'} />
          </div>
        </button>
      </div>
    </div>
  );
};
