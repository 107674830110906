import ChannelContext from '@jetshop/core/components/ChannelContext';
import { FlyoutTarget, FlyoutTrigger } from '@jetshop/ui/Modal/Flyout';
import React, { useContext } from 'react';
import Selector from './Selector';
import { styled } from 'linaria/react';
import { theme } from '../../../Theme';

const Flyout = styled('div')`
  background-color: white;
  position: absolute;
  z-index: 5;
  /* top: calc(100% + ((54px - 100%) / 2)); */
  right: 0;
  top: 30px;

  ${theme.below.lg} {
    left: -80px;
    right: 0;
  }
`;

const CountryFlag = styled('img')`
  height: 20px;
  width: 20px;
  margin-right: 5px;
`;

const ChannelWrapper = styled('div')`
  text-align: center;
  position: relative;
`;

export default function HeaderChannelSelector() {
  const {
    selectedChannel,
    channels,
    updateChannel,
    selectedChannelGroup
  } = useContext(ChannelContext);

  const selectedLanguage = selectedChannel?.language?.culture?.slice(-2);

  return (
    <ChannelWrapper>
      <FlyoutTrigger id="channel-selector">
        {({ showTarget, hideTarget, isOpen }) => (
          <button
            data-testid="channel-selector-button"
            onClick={isOpen ? hideTarget : showTarget}
            style={{ background: 'transparent' }}
          >
            <CountryFlag
              src={`https://countryflags.jetshop.io/${selectedLanguage}/flat/32.png`}
              alt="Country Flag"
              width="16"
              height="12"
            />
            {/* {selectedChannel.country.name} */}
          </button>
        )}
      </FlyoutTrigger>
      <FlyoutTarget id="channel-selector">
        {({ isOpen, hideTarget }) => (
          <Flyout>
            <Selector
              channels={selectedChannelGroup || channels}
              hideTarget={hideTarget}
              selectedChannel={selectedChannel}
              updateChannel={updateChannel}
            />
          </Flyout>
        )}
      </FlyoutTarget>
    </ChannelWrapper>
  );
}
