import React from 'react';
import { cx } from 'linaria';
import { SharedSwiper } from '../../Layout/Swiper/SharedSwiper';
import { Above, Below } from '@jetshop/ui/Breakpoints';
import { useBaseFontSize } from '../../../hooks/useBaseFontSize';
import { productRowStyle } from './ProductRow.style';
import { theme } from '../../Theme';
import { styled } from 'linaria/react';
import { CTA } from 'r3-lib/dist/r3lib';

const ProductRowOuterWrapper = styled('div')`
  width: 100%;
  margin: auto;
  display: flex;

  .swiper-button-prev,
  .swiper-button-next {
    background-color: ${theme.colors.layout.productRowArrows};
  }

  .product-row-wrapper {
    margin: 44px 0 30px 0;
  }

  .swiper-slide-active {
    margin-left: -10px;
  }

  .swiper-slide-prev {
    margin-left: -10px;
    margin-right: 10px;
  }

  .wrapper {
    padding-left: ${props => props['padding-left']}px;
  }

  ${theme.below.lg} {
    flex-direction: column;
    .product-row-wrapper {
      margin: 0;
    }
    .wrapper {
      padding-left: 20px;
    }
    .swiper-button-prev {
      display: none;
    }
  }
`;

const RowHeaderWrapper = styled('div')`
  width: 100%;
  max-width: 300px;
  padding: 55px 40px 30px 40px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .cta.light_outline {
    border-color: ${theme.colors.black};
  }

  .cta.light_outline a {
    color: ${theme.colors.black};
  }

  .title {
    &.hideMobileTitle {
      ${theme.below.lg} {
        display: none;
      }
    }
  }
  .button {
    width: 100%;
    &.hideMobileButton {
      ${theme.below.lg} {
        display: none;
      }
    }
  }

  h2 {
    font-weight: 700;
    font-size: 32px;
    line-height: 38px;
    display: flex;
    align-items: center;
  }

  &.few {
    max-width: 35%;
  }

  ${theme.below.lg} {
    max-width: 100% !important;
    align-items: flex-start;
    background: #fff !important;
    padding: 30px 20px 20px 20px;

    h2 {
      font-size: 24px;
      line-height: 29px;
      margin-bottom: 10px;
    }
  }
`;

export const RowHeader = ({
  title,
  buttonText,
  backgroundColor,
  textColor,
  link,
  fewCards,
  hideMobileTitle,
  hideMobileButton
}) => {
  return (
    <RowHeaderWrapper
      style={{ background: `${backgroundColor}` }}
      className={fewCards && 'few'}
    >
      {title && (
        <h2
          className={`title ${hideMobileTitle && ' hideMobileTitle'} `}
          style={{ color: `${textColor}` }}
        >
          {title}
        </h2>
      )}

      {link && (
        <CTA
          className={`button light_outline ${hideMobileButton &&
            ' hideMobileButton'} `}
          link={link}
        >
          {buttonText && buttonText}
        </CTA>
      )}
    </RowHeaderWrapper>
  );
};

export const ProductRow = ({ fullWidth, gap, cartPage, style, children }) => {
  const desktopChildrenLength = children?.length < 4 ? children?.length : 4;
  const mediumChildrenLength = children?.length < 2.3 ? children?.length : 2.3;
  const mobileChildrenLength = children?.length < 1.3 ? children?.length : 1.3;
  const desktopSlidesPerView = desktopChildrenLength;
  const mediumSlidesPerView = cartPage ?? mediumChildrenLength;
  const mobileSlidesPerView = cartPage ? 1.5 : mobileChildrenLength;

  const rem = useBaseFontSize();

  // TODO - Move to HelperCollection || change to px
  const remToPx = value => {
    const numString = value?.replace('rem', '');
    if (numString?.includes('px')) {
      return null;
    }
    const num = parseFloat(numString);

    return isNaN(num) ? null : num * rem;
  };

  return (
    <Above breakpoint="lg">
      {aboveLg => {
        return (
          <Below breakpoint="sm">
            {belowMd => {
              return (
                <section
                  className={cx(
                    'product-row-wrapper',
                    style,
                    fullWidth?.value && 'fullWidth'
                  )}
                >
                  <div className="wrapper">
                    <SharedSwiper
                      gap={
                        gap?.value ? remToPx(theme.layout.gaps[gap?.value]) : 0
                      }
                      slidesPerView={
                        aboveLg
                          ? desktopSlidesPerView
                          : belowMd
                          ? mobileSlidesPerView
                          : mediumSlidesPerView
                      }
                    >
                      {children}
                    </SharedSwiper>
                  </div>
                </section>
              );
            }}
          </Below>
        );
      }}
    </Above>
  );
};

export const StyledProductRow = ({
  fullWidth,
  gap,
  padding,
  cartPage,
  children,
  title,
  link,
  buttonText,
  backgroundColor,
  textColor
}) => {
  return (
    <ProductRowOuterWrapper
      padding-left={children?.length <= 4 ? 10 : 30}
      style={{
        maxWidth: `${fullWidth?.value ? '100%' : theme.layout.maxWidth}`,
        padding: `0 ${padding?.value ?? 0}px`
      }}
    >
      <RowHeader
        hideMobileButton={true}
        title={title?.value}
        buttonText={buttonText?.value}
        backgroundColor={backgroundColor?.value}
        textColor={textColor?.value}
        link={link?.value}
      />
      <ProductRow
        fullWidth={fullWidth}
        gap={gap}
        padding={padding}
        cartPage={cartPage}
        style={productRowStyle}
      >
        {children}
      </ProductRow>
      <Below breakpoint="lg">
        {matches =>
          matches && (
            <RowHeader
              hideMobileTitle={true}
              title={title?.value}
              buttonText={buttonText?.value}
              backgroundColor={backgroundColor?.value}
              textColor={textColor?.value}
              link={link?.value}
            />
          )
        }
      </Below>
    </ProductRowOuterWrapper>
  );
};
